<template>
  <screen_width_cont>
    <div
      class="cont_300_basico ancho_acotado recoverPass_cont"
      @click.stop="colapsarIdiomaSelect = true"
    >
      <div class="header_top"><headerBack @back="backLocal" /></div>

      <!-- título principal -->
      <div class="recoverPassTitleRut title">
        {{ t("app.recoverPass.tit") }}
      </div>

      <div class="recoverPassTextRut text_basico f_w_400">
        {{ t("app.recoverPass.recoverPassTextRut") }}
      </div>

      <div class="recoverPassInputsRut">
        <inputBox
          typeInput="rut"
          placeHolder="Rut"
          requerido="*"
          :rut="true"
          :error="errorRut"
          @dispatchChangeData="updateRut"
          :mensajeError="[
            t('configuracion.data.textos_errores.campoObligatorio'),
            t('configuracion.data.textos_errores.rut'),
          ]"
          :mensajeOk="{ mostrar: false, texto: '' }"
          :borrarDatos="borrarDatos"
          dataInicial=""
          txt_ejemplo="Ej: XXXXXXXX-X"
        />
      </div>

      <div class="recoverPassBtnRut">
        <btnAction
          :textBtn="t('app.comunes.btns.continuar')"
          @action="solicitarCodigo"
          :disabled="!btnActive"
          colorBack="rojo"
          colorText="blco"
          :estadoBtn="estadoBtn"
          :msg_completar="t('app.comunes.btns.rut_incorrecto')"
        />
      </div>

      <div class="recoverPassEtapas">
        <pasosGiro paso="1" totales="3" />
      </div>

      <div class="recoverPassFooterRut f_w_500">
        <span>{{ t("app.recoverPass.recoverPassFooterRut1") }}&nbsp;</span>
        <span @click="ayudaRecoverPass" class="recoverPassFooterRut2 f_w_700">{{
          t("app.recoverPass.recoverPassFooterRut2")
        }}</span>
      </div>
    </div>
  </screen_width_cont>
</template>

<script>
import { modalController, alertController } from "@ionic/vue";
import { useI18n } from "vue-i18n";
import btnAction from "../components/generales/btnAction.vue";
import inputBox from "../components/generales/inputBox.vue";
import headerBack from "../components/generales/headerBack.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import recoverPassHelp from "./modales/recoverPassHelp.vue";
import pasosGiro from "../components/generales/pasosProceso.vue";
import screen_width_cont from "../components/menuSp/screen_width_cont.vue";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },

  name: "recoverPass",
  components: {
    btnAction,
    headerBack,
    inputBox,
    pasosGiro,
    screen_width_cont,
  },
  data() {
    return {
      errorRut: false,
      btnActive: true,
      borrarDatos: false,
      txtRut: "",
      estado_validaciones: false,
      error_respForgotPass: "",
    };
  },
  computed: {
    ...mapState(["respForgotPass", "widthScreen"]),

    estadoBtn() {
      let aux = "inactive";
      if (this.estado_validaciones) {
        aux = "active";
      }
      return aux;
    },
  },
  methods: {
    ...mapMutations(["updateBloquearNavegacion", "setshowSpinner"]),
    ...mapActions(["forgotPassword", "validarString"]),

    backLocal() {
      this.$router.push({ name: "logIn", replace: true });
    },
    async updateRut(data) {
      // console.log(data);
      this.txtRut = data;

      let errores = await this.validarString({
        string: this.txtRut,
        validaciones: ["notempty", "charmin-9", "charmax-10", "rut-empresa"],
      });
      // console.log(errores);
      if (errores.length > 0) {
        this.estado_validaciones = false;
      } else {
        this.estado_validaciones = true;
      }
    },
    async solicitarCodigo() {
      // console.log("Rut", this.txtRut);
      if (this.txtRut != "") {
        this.btnActive = false;

        this.forgotPassword(this.txtRut);
      } else {
        this.setshowSpinner(false);
      }
    },
    async ayudaRecoverPass() {
      this.updateBloquearNavegacion(true);
      const modal = await modalController.create({
        component: recoverPassHelp,
        // cssClass: 'my-custom-class',
        // componentProps: {
        //   title: 'Formulario'
        // },
      });

      modal.onDidDismiss().then((data) => {
        this.updateBloquearNavegacion(false);

        if (data.data.registrar) {
          this.$router.push({ name: "registro" });
        }
      });
      return modal.present();
    },

    async presentAlert(data) {
      let alert = await alertController.create({
        header: "Error",
        subHeader: data.msg,
        backdropDismiss: false,
        buttons: [`Ok`],
      });
      await alert.present();
    },
  },
  watch: {
    respForgotPass() {
      // console.log("respForgotPass");
      // this.mensajeError = "";
      // this.mensajeCodigo = "";
      if (this.respForgotPass.status) {
        if (this.respForgotPass.resp) {
          this.borrarDatos = !this.borrarDatos;
          this.$router.push({ name: "autentificacionRP", replace: true });
        } else {
          if (this.respForgotPass.msj == "ERROR_CLI_006") {
            this.presentAlert({
              msg: this.t(
                `configuracion.errores_srv.Cliente.${this.respForgotPass.msg}`
              ),
            });
          }
        }
        this.btnActive = true;
      }
    },
  },
  ionViewWillLeave() {
    this.txtRut = "";
    this.borrarDatos = !this.borrarDatos;
  },
};
</script>

<style>
.recoverPass_cont {
  height: 96%;
}
.recoverPassTitleRut {
  margin-top: 3.125rem;

  text-align: center;
  color: var(--customA-801B1C);
}
.recoverPassTextRut {
  margin-top: 2.8125rem;
  /* font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.6875rem;
  line-height: 18px; */
  display: flex;
  align-items: flex-end;
  text-align: justify;
  /* letter-spacing: 0.5px; */
  color: var(--customG-787878);
}
.recoverPassInputsRut {
  margin-top: 4.5rem;
}
.recoverPassReSendRut {
  margin-top: 2.8125rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 0.6875rem;
  line-height: 13px;
  /* display: flex;
  align-items: flex-end; */
  text-align: center;
  letter-spacing: 0.5px;
  text-decoration-line: underline;
  color: var(--customG-787878);
}
.recoverPassBtnRut {
  margin-top: 5rem;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
}
.recoverPassFooterRut {
  position: absolute;
  bottom: 3rem;
  left: 0;
  right: 0;
  font-family: "Roboto";
  font-style: normal;
  /* font-weight: 500; */
  font-size: 0.875rem;
  line-height: 1rem;
  text-align: center;
  letter-spacing: 0.5px;
  color: var(--customH-4C4C4C);
}
.recoverPassFooterRut2 {
  /* font-weight: 500; */
  color: var(--customC-614AD3);
}
.recoverPassEtapas {
  margin-top: 1rem;
  width: 100%;
  text-align: center;
}

/* ////////////////-------------------------------------/////////// */
@media (min-width: 600px) {
  .recoverPassFooterRut {
    padding-top: 6rem;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>