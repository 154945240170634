<template>
  <div class="passRecoverHelp">
    <div class="passRecoverHelpModal">
      <div>
        <headerCerrar @cancelar="cerrarModal" colorIcon="rojoOscuro" />
      </div>

      <div class="passRecoverHelpModalTitle title">
        {{ t("app.recoverPassHelp.tit") }}
      </div>

      <div class="passRecoverHelpModalText">
        {{ t("app.recoverPass.help_text") }}
      </div>

      <div class="passRecoverHelpModalSubText">
        <div>{{ t("app.recoverPass.contacto_sac") }}.</div>
        <!-- <div>{{t("app.recoverPass.contacto_sac_horario_1")}}.</div> -->
        <div><img :src="whatsApp" alt="" srcset="" /></div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  // IonContent,
  // IonPage,
  modalController,
} from "@ionic/vue";
import headerCerrar from "../../components/generales/headerCerrar.vue";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  name: "registroDisclaimer",
  components: {
    // IonContent,
    // IonPage,
    headerCerrar,
  },
  data() {
    return {
      whatsApp: require("../../assets/imgs/whatsAppTelef.svg"),
    };
  },
  computed: {},
  methods: {
    changeStateChekLocal(estado) {
      this.aceptoCondiciones = estado;
      this.checkBoxError = false;
    },
    cerrarModal() {
      modalController.dismiss({ dismissed: true, registrar: false });
    },
    // async presentAlert() {
    //   let alert = await alertController.create({
    //     // header: this.title,
    //     subHeader: this.subTitle,
    //     backdropDismiss: false,
    //     buttons: [
    //       {
    //         text: `Ok`,
    //         role: "cancel",
    //         cssClass: "regisDisclAlertBtn",
    //       },
    //     ],
    //     cssClass: "regisDisclAlert",
    //   });
    //   await alert.present();
    // },
    registrar() {
      if (!this.aceptoCondiciones) {
        // this.checkBoxError = true;
        // this.subTitle = "Debes leer y aceptar términos y condiciones.";
        // this.presentAlert();
      } else {
        // this.checkBoxError = false;
        modalController.dismiss({ dismissed: true, registrar: true });
      }
    },
    ActionLocal() {
      if (!this.aceptoCondiciones) {
        this.subTitle = "Debes leer y aceptar términos y condiciones.";
        this.presentAlert();
      } else {
        this.$router.push({ name: "registro" });
      }
    },
  },
};
</script>

<style>
.passRecoverHelp {
  /* background: #E5E5E5; */
  background-color: #ffffff;
  /* background-color: transparent; */
  width: 100%;
  height: 100%;
  /* overflow-y: hidden; */
  padding: 0 1.68rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.passRecoverHelpModal {
  display: block;
  background-color: #ffffff;
  border-radius: 20px;
  /* width: 85%;
  max-width: 85%; */
  /* margin: 15% auto auto auto; */

  width: 19.125rem;
  height: fit-content;
  /* margin: 15% 7% auto 7%; */

  padding: 1.5rem;
  box-shadow: 0px 1px 11px 2px rgba(0, 0, 0, 0.15);
}
.passRecoverHelpModalTitle {
  color: var(--customA-801B1C);
  text-align: center;
}
.passRecoverHelpModalText {
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2rem;
  color: var(--customG-787878);
  text-align: justify;
}
.passRecoverHelpModalSubText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1.1rem;
  text-align: center;
  letter-spacing: 0.5px;
  margin-top: 1rem;
  color: var(--customC-614AD3);
}

/* ////////////////-------------------------------------/////////// */
@media (min-width: 600px) {
  .passRecoverHelp {
    width: 60%;
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>